import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PortfolioItem from "../../components/portfolio-item";

import '../../components/index.scss'
import HomeLink from "../../components/home-link";

const BasementGuardianPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "portfolio/basement-guardian/icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero01: file(relativePath: { eq: "portfolio/basement-guardian/01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero02: file(relativePath: { eq: "portfolio/basement-guardian/02.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero03: file(relativePath: { eq: "portfolio/basement-guardian/03.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = 'Basement Guardian';
  const summary = `The newly developed IoT8020 platform, built from the ground up with speed and re-usability in mind,
    was used for this web application that was eventually packed and distributed into the App and Play Store respectively.`;

  return <Layout>
    <SEO title={title} description={summary}/>
    <HomeLink/>
    <PortfolioItem
      year={2017}
      role={'Full Stack Developer'}
      frameworks="Angular, AWS IoT SDK, Serverless"
      imageQuery={imageQuery}
      name={title}
      stack="Node.js, TypeScript, HTML, CSS"
      url={'https://apps.apple.com/us/app/basement-guardian/id1313894738'}
      summary={summary}
    />
  </Layout>
};

export default BasementGuardianPage
